<script setup>
const props = defineProps({
  orderType: {
    type: String,
    required: true,
  },
  orderId: {
    type: Number,
    required: true,
  },
  status: {
    type: String,
    required: true,
  },
});

const formatOrderType = (type) => {
  switch (type.toLowerCase()) {
    case "inbound":
      return "IN";
    case "outbound":
      return "OUT";
    case "transfer":
      return "TR";
    default:
      return "N/A";
  }
};
</script>

<template>
  <v-card class="pa-2">
    <v-card-title class="d-flex justify-space-between">
      <div>
        {{ orderType.toUpperCase() }} ORDER #{{ formatOrderType(orderType) }}-{{
          orderId
        }}
      </div>
      <div>
        <v-chip color="yellow"
          >STATUS:
          <span class="font-weight-bold ml-1">{{ status.toUpperCase() }}</span></v-chip
        >
      </div></v-card-title
    >
  </v-card>
</template>
